export const events = {
  Idling: "0",
  Speeding: "1",
  Trip: "2",
  Geofence: "3",
  Battery: "4",
  Ignition: "5",
  Custom: "6",
};

export const eventTypes = [
  {
    id: events.Idling,
    name: "Idling",
  },
  {
    id: events.Speeding,
    name: "Speeding",
  },
  // {
  //   id: events.Trip,
  //   name: "Trip",
  // },
  {
    id: events.Geofence,
    name: "Geofence",
  },
  {
    id: events.Battery,
    name: "Low Battery",
  },
  {
    id: events.Ignition,
    name: "Ignition Status Change",
  },
  {
    id: events.Custom,
    name: "Custom",
  },
];

export const planTypes = [
  {
    id: "gps_based",
    name: "GPS Based",
  },
  {
    id: "ignition_based",
    name: "Ignition Based",
  },
  {
    id: "smart",
    name: "Smart",
  },
];

export const batteryTypes = [
  {
    id: "internal",
    name: "Internal",
  },
  {
    id: "external",
    name: "External",
  },
  {
    id: "ev",
    name: "EV",
  },
];

export const unitTypes = [
  {
    id: "km_per_hour",
    name: "Km/hr",
  },
  {
    id: "mile_per_hour",
    name: "Mile/hr",
  },
];

export const batteryUnitTypes = [
  {
    id: "percent",
    name: "%",
  },
  {
    id: "volts",
    name: "V",
  },
];

export const simCardTypes = [
  {
    id: "esim",
    name: "Digital SIM (eSIM)",
  },
  {
    id: "physical",
    name: "Physical SIM",
  },
];

export const simCapabilities = [
  {
    id: "2G",
    name: "2G",
  },
  {
    id: "3G",
    name: "3G",
  },
  {
    id: "4G",
    name: "4G",
  },
  {
    id: "5G",
    name: "5G",
  },
];

export const variableTypes = [
  {
    id: "number",
    name: "Number",
  },
  {
    id: "text",
    name: "Text",
  },
  {
    id: "list",
    name: "List",
  },
  {
    id: "switch",
    name: "Switch",
  },
  {
    id: "timestamp",
    name: "Timestamp",
  },
  {
    id: "date",
    name: "Date",
  },
  {
    id: "duration",
    name: "Duration",
  },
];

export const classifications = [
  {
    id: "tracking_data_device",
    name: "Tracking Data - Device",
  },
  {
    id: "tracking_data_custom",
    name: "Tracking Data - Custom",
  },
  {
    id: "tracking_data_api",
    name: "Tracking Data - API",
  },
  {
    id: "event_data",
    name: "Event Data",
  },
  {
    id: "trip_data",
    name: "Trip Data",
  },
];

export const applications = [
  {
    id: "all",
    name: "ALL",
  },
  {
    id: "management_portal",
    name: "Management Portal",
  },
  {
    id: "tracking_portal",
    name: "Tracking Portal",
  },
  {
    id: "mobile",
    name: "Mobile",
  },
];

export const categories = [
  {
    id: "general",
    name: "General",
  },
  {
    id: "driver_behaviour",
    name: "Driver Behaviour",
  },
  {
    id: "security",
    name: "Security",
  },
];

export const numberTimestampDateVariableTypeCondition = [
  {
    id: "is_present_only",
    name: "Is present only",
  },
  {
    id: "less_than",
    name: "Less than",
  },
  {
    id: "less_than_or_equal",
    name: "Less than or equal",
  },
  {
    id: "greater_than",
    name: "Greater than",
  },
  {
    id: "greater_than_or_equal",
    name: "Greater than or equal",
  },
  {
    id: "equal",
    name: "Equal",
  },
  {
    id: "less_than_or_greater_than",
    name: "Less than or greater than",
  },
];

export const textVariableTypeCondition = [
  {
    id: "is_present_only",
    name: "Is present only",
  },
  {
    id: "contains",
    name: "Contains",
  },
  {
    id: "equal",
    name: "Equal",
  },
  {
    id: "regex",
    name: "Regex",
  },
];

export const switchVariableTypeCondition = [
  {
    id: "is_present_only",
    name: "Is present only",
  },
  {
    id: "true",
    name: "True",
  },
  {
    id: "false",
    name: "False",
  },
];

export const userRoles = [
  {
    id: "Fleet Admin",
    name: "Fleet Admin",
  },
  {
    id: "Admin",
    name: "Admin",
  },
];
