import React, { useEffect, useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable } from "../Shared/";
import { useGetDModelsQuery } from "../services";
import { DModelForm } from "./Form";
import { DeviceModel } from "./show";
import { Link } from "react-router-dom";
import { useQuery } from "../../../hooks";
import { TickOrCross, SvgIcon } from "../../Shared";

export const DModelsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_device_manufacturer_name_cont",
  });

  const queryParams = {};

  const query = useQuery();
  let filterHeader = "";
  const filters = [
    { label: " Manufacturer: ", key: "device_manufacturer_id" },
    { label: " Variable: ", key: "variable_id" },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  let device_manufacturer_id = query.get("device_manufacturer_id");

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
    refetch,
  } = useGetDModelsQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    ...queryParams,
    q,
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showControlSignalVariableDetails, setShowControlSignalVariableDetails] = useState(false);

  useEffect(() => {
    if (!showControlSignalVariableDetails) {
      refetch();
    }
  }, [showControlSignalVariableDetails]);

  const additionalActions = [
    {
      component: (data) => (
        <SvgIcon
          wrapperClass="clickable"
          name="settings"
          onClick={() => setShowControlSignalVariableDetails(data)}
          title="Configurations"
        />
      ),
    },
  ];

  const headers = [
    {
      label: "Name",
      key: "name",
      className: "name",
    },
    {
      label: "Device manufacturer",
      key: "device_manufacturer_name",
      className: "device-manufacturer",
    },
    { label: "Version", key: "version", className: "version" },
    { label: "Port", key: "port", className: "version" },
    { label: "Year", key: "year", className: "year" },
    {
      label: "Remote Lock",
      nestedValue: true,
      className: "status",
      getNestedValue: ({ is_lockable }) => <TickOrCross flag={is_lockable} />,
    },
    {
      label: "Variables",
      className: "variables text-center",
      nestedValue: true,
      getNestedValue: ({ id, device_model_variables_count, name }) => {
        return (
          <>
            {device_model_variables_count > 0 ? (
              <Link
                to={`/superadmin/others/variables?device_model_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {device_model_variables_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: "Control Signals",
      key: "device_model_control_signals_count",
      alternate: 0,
      className: "control-signal text-center",
    },
    {
      label: "Devices",
      nestedValue: true,
      className: "devices text-center",
      getNestedValue: ({ id, devices_count, name }) => {
        return (
          <>
            {devices_count > 0 ? (
              <Link
                to={`/superadmin/devices/list?device_model_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {devices_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          groupName="device"
          heading="Devices"
          handleSearchKey={(value) => setSimpleSearch(value)}
          filterText={filterHeader && `Filtered by ${filterHeader}`}
          simpleSearchPlaceHolder="Search by Name, Manufacturer"
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          parentName={device_manufacturer_id ? data.data[0]?.device_manufacturer_name : null}
          className="device-models"
          // auditResource="DeviceModel"
          // auditKey="name"
          additionalActions={additionalActions}
          systemDefault={false}
        />
      </article>

      {showForm && <DModelForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
      {showControlSignalVariableDetails && (
        <DeviceModel
          ControlSignalVariableDetails={showControlSignalVariableDetails}
          closeForm={() => setShowControlSignalVariableDetails(false)}
        />
      )}
    </>
  );
};
